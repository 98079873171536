<template>
  <div class="text-center">
    <v-snackbar dark v-model="open" :multi-line="multiLine">
      {{ text }}
     <!-- <v-btn :color="color" text top @click="close()">Ok</v-btn> -->
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["text", "color", "snackbar"],
  data: () => ({
    open: false,
    multiLine: true
  }),
  watch: {
    open: function(val) {
      if (!val) {
        this.$emit("close", "")
      }
    },
    snackbar: function(val) {
      if (!val) {
        this.open = false
        this.$emit("close", "")
      } else {
        this.open = true
      }
    }
  },
  methods: {
    close() {
      this.open = false;
      this.$emit("close", "")
    }
  }
};
</script>