import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import mainConfigApp from '../config/config'

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('jwt') || '',
    user: {},
    dataCli: {}
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    auth_request(state) {
      state.status = 'carregando'
    },
    data_change(state, newData) {
      state.dataCli = newData
    },
    auth_success(state, authResp) {
      state.status = 'sucesso'
      state.token = authResp.token
      state.user = authResp.user
      state.dataCli = authResp.dataCli
    },
    auth_error(state) {
      state.status = 'erro'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.dataCli = {}
      state.user = {}
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: mainConfigApp.login, data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            const user = resp.data.user
            const dataCli = resp.data.dataCli
            localStorage.setItem('jwt', token)
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', { token, user, dataCli })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('jwt')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('jwt')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    changeCliData({ commit }, newData) {
      return new Promise((resolve, reject) => {
        commit('data_change', newData)
        resolve()
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    isAdminUser: state => state.user.is_admin,
    getDataCli: state => state.dataCli
  }
})