import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login'),
    meta: {
      guest: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({path: '/login', params: { nextUrl: to.fullPath }})
      return
    } else {
      if (to.matched.some(record => record.meta.is_admin)) {
        if (store.getters.isAdminUser) {
          next()
          return
        }
      } else {
        next()
        return
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    next()
    return
  } else {
    next({path: '/login', params: { nextUrl: to.fullPath }})
    return
  }
})

export default router
