import Vue from 'vue'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
import Axios from 'axios'
import './registerServiceWorker'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import mainConfigApp from '../src/config/config'

Vue.prototype.$http = Axios;
VueClipboard.config.autoSetContainer = true
Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.prototype.$mainConfigApp = mainConfigApp

const token = localStorage.getItem('jwt')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
