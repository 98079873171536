const mainConfigApp = {
    forgotPass : 'https://cliente.cobrecom.com.br/api/forgotPass',
    login:       'https://cliente.cobrecom.com.br/api/auth',
    getTitulos:  'https://cliente.cobrecom.com.br/api/getTitulos',
    haveBoleto:  'https://cliente.cobrecom.com.br/api/haveBoleto',
    pdfBoleto:   'https://cliente.cobrecom.com.br/api/pdfBoleto',
    changePass:  'https://cliente.cobrecom.com.br/api/changepass'
    
    /*forgotPass : 'http://192.168.1.26:3000/api/forgotPass',
    login:       'http://192.168.1.26:3000/api/auth',
    getTitulos:  'http://192.168.1.26:3000/api/getTitulos',
    haveBoleto:  'http://192.168.1.26:3000/api/haveBoleto',
    pdfBoleto:   'http://192.168.1.26:3000/api/pdfBoleto',
    changePass:  'http://192.168.1.26:3000/api/changepass'*/
}

export default mainConfigApp