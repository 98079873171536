<template>
  <v-app>
    <v-main>
      <snack
        :text="objAlert.message"
        :color="objAlert.type"
        :snackbar="objAlert.show"
        @close="closeSnack()"
      />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import snack from "@/components/snackBar.vue";

export default {
  name: "App",

  components: {
    snack
  },
  created: function() {
    this.$http.interceptors.response.use(undefined, err => {
      let res = err.response;
      return new Promise((resolve, reject) => {
        if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
           this.showSnak('Login expirado! realize um novo login', "error");
          this.$store.dispatch('logout');
          this.$router.push("login");
        } else if (
          res.status === 500 &&
          res.config &&
          !res.config.__isRetryRequest
        ) {
          this.showSnak(res.data.result, "error");
        }
        throw err;
      });
    });
  },
  data: () => ({
    objAlert: {
      type: "",
      message: "",
      show: false
    }
  }),
  methods: {
    closeSnack: function() {
      this.objAlert.show = false;
      this.objAlert.message = "";
    },
    showSnak: function(cMsg, cType, cCloseTxt = "OK") {
      this.objAlert.type = cType;
      this.objAlert.closeText = cCloseTxt;
      this.objAlert.message = cMsg;
      this.objAlert.show = true;
      setInterval(() => {
        this.objAlert.show = false;
        this.objAlert.message = "";
      }, 10000);
    }
  }
};
</script>
